import React from 'react'
// import { Link } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'

import Main from '../components/Main'
import Layout from '../components/Layout'
import S from '../components/seo'

const IndexPage = () => (
  <Layout>
    <Container>
      <S />
      <Main />
    </Container>
  </Layout>
)

export default IndexPage
