import React from 'react'

import Icon from '../Icon'

import * as s from './Main.module.scss'

import DATA from './constants'

const Main = () => {
  return (
    <div className={s.main}>
      <Icon name="logo" size={200} />
      <ul className={s.socials}>
        {DATA.map(({ icon, link }) => (
          <li>
            <a href={link} target="_blank" rel="noreferrer">
              <Icon name={icon} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Main
