const DATA = [
  {
    icon: 'in',
    link: 'https://www.linkedin.com/in/salexpro/',
  },
  {
    icon: 'gh',
    link: 'https://github.com/salexpro',
  },
  {
    icon: 'tg',
    link: 'https://t.me/salexpro',
  },
]

export default DATA
